import React from 'react'
import Grid2 from '@mui/material/Grid2'
import { styled } from '@mui/material/styles'
import { camelCase } from 'lodash'

// Estiliza o componente Grid2 e define um nome para o componente personalizado
const StyledGrid = styled(Grid2, {
  name: 'Cowmed',
  slot: 'grid',
  shouldForwardProp: prop => prop !== 'divider',
})(({ divider }) => ({
  position: 'relative',
  ...(divider && {
    '&::after': {
      content: '""',
      position: 'absolute',
      color: '#EFEFEF',
      [divider]: 0,
      [camelCase(`border-${divider}`)]: '1px solid',
      ...(['right', 'left'].includes(divider)
        ? {
            top: '50%',
            height: '70%',
            transform: 'translateY(-50%)',
          }
        : {
            left: '50%',
            width: '70%',
            transform: 'translateX(-50%)',
          }),
    },
  }),
}))

/**
 * Componente de Grid estilizado com suporte a tamanhos dinâmicos e divisores.
 *
 * @param {object} [props.size={}] - Tamanhos responsivos para o Grid.
 * @param {number} [props.size.xs] - Tamanho para telas extra pequenas.
 * @param {number} [props.size.sm] - Tamanho para telas pequenas.
 * @param {number} [props.size.md] - Tamanho para telas médias.
 * @param {number} [props.size.lg] - Tamanho para telas grandes.
 * @param {number} [props.size.xl] - Tamanho para telas extra grandes.
 * @param {string} [props.divider] - Direção do divisor ('top', 'bottom', 'left', 'right').
 * @returns {React.Element} O componente Grid estilizado.
 */
const CowmedGrid = React.forwardRef(({ ...props }, ref) => {
  // Retorna o GridItem estilizado com suporte a ref e passando as props restantes
  return <StyledGrid ref={ref} {...props} />
})

export default CowmedGrid
