import React, { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import isElement from 'lodash/isElement'
import Dialog from '@mui/material/Dialog'
import MuiDialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import MuiButton from '@mui/material/Button'
import Button from '../FormControl/Button'
import Translate from '#/components/Translate'
import LineClampStyle from '#/styles/LineClamp'
import LinearIndeterminate from '#/components/Loader'
import CowmedGrid from '#/components/Grid'

const sxStyles = {
  dialogTitle: {
    margin: 0,
  },
  titleContainer: {
    position: 'relative',
  },
  closeBtn: {
    top: 0,
    right: 0,
    padding: 0,
    position: 'absolute',
  },
  paperScrollPaper: {
    overflow: 'visible',
  },
  root: {
    overflowX: 'hidden',
  },
  lineClamp: {
    ...LineClampStyle,
  },
}

const DialogTitle = props => {
  const { onClose, title, subtitle } = props
  return (
    <MuiDialogTitle sx={sxStyles.dialogTitle}>
      <CowmedGrid container alignItems="flex-start">
        <CowmedGrid size={12} sx={sxStyles.titleContainer}>
          {title}
          {subtitle && (
            <Typography variant="caption" display="block">
              {subtitle}
            </Typography>
          )}
          {onClose ? (
            <IconButton sx={sxStyles.closeBtn} onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          ) : null}
        </CowmedGrid>
      </CowmedGrid>
    </MuiDialogTitle>
  )
}

const SimpleDialog = props => {
  const {
    autoClose,
    autoCloseTime = 3000,
    onClose,
    open,
    closable = true,
    maxWidth = 'md',
    action,
  } = props

  let closeTime = autoCloseTime

  // Modais com actions(botões de novo registro) adiciona mais 2s
  if (action) {
    closeTime += 2000
  }

  // Estado para controlar o tempo decorrido
  const [timer, setTimer] = useState(0)

  // Estado para controlar a porcentagem de fechamento automático
  const [autoClosePercent, setAutoClosePercent] = React.useState(0)

  useEffect(() => {
    let intervalToClose

    // Verifica se o componente está aberto e se há um tempo de fechamento automático configurado
    if (open && autoClose) {
      // Configura um intervalo para incrementar o temporizador a cada 100 milissegundos
      intervalToClose = setInterval(() => {
        setTimer(prevState => prevState + 100)
      }, 100)
    }

    return () => clearInterval(intervalToClose)
  }, [open, autoClose])

  // Efeito para calcular a porcentagem de fechamento automático
  useEffect(() => {
    // Verifica se o tempo de fechamento automático está definido
    if (autoClose) {
      // Verifica se o temporizador ainda não atingiu o tempo total
      if (timer <= closeTime) {
        // Calcula a porcentagem com base no tempo decorrido
        setAutoClosePercent((timer / closeTime) * 100)
      } else if (onClose) {
        // Se o tempo decorrido ultrapassou o tempo total e há uma função onClose
        onClose()
      }
    }
  }, [timer, autoClose, onClose])

  const handleOnClose = (event, reason) => {
    if (reason === 'backdropClick' && !closable) {
      return false
    }
    if (reason === 'escapeKeyDown' && !closable) {
      return false
    }
    if (typeof props.onClose === 'function') {
      props.onClose(event, reason)
    }
  }

  const actionBtn = (() => {
    if (!isEmpty(props.action)) {
      return React.isValidElement(props.action) || isElement(props.action) ? (
        <React.Fragment>{props.action}</React.Fragment>
      ) : (
        <Button {...props.action}>{props.action.title}</Button>
      )
    }
    return null
  })()

  return (
    <Dialog
      keepMounted={props.keepMounted}
      fullWidth={props.fullWidth}
      maxWidth={maxWidth}
      onClose={handleOnClose}
      open={open}
    >
      {autoClose ? (
        <LinearIndeterminate status="started" value={autoClosePercent} />
      ) : null}
      <DialogTitle {...props} />
      <DialogContent sx={{ ...sxStyles.root, ...props.contentContainerStyles }}>
        {props.element}
      </DialogContent>
      {!isEmpty(props.action) || props.withBottomCloseButton ? (
        <DialogActions>
          <MuiButton
            onClick={props.onClose}
            color="secondary"
            variant="outlined"
            disabled={props.status === 'started'}
          >
            {Translate({ messageKey: 'close' })}
          </MuiButton>
          {actionBtn}
        </DialogActions>
      ) : null}
    </Dialog>
  )
}

export default SimpleDialog
